import { useAddress, useMetamask, useEditionDrop, useToken, useVote, useNetwork } from '@thirdweb-dev/react'
import { ChainId } from '@thirdweb-dev/sdk'
import { useState, useEffect, useMemo } from 'react'
import { AddressZero } from '@ethersproject/constants'

const App = () => {
  // use the hooks thirdweb gives us
  const address = useAddress()
  const network = useNetwork()
  const connectWithMetamask = useMetamask()
  console.log('👋 Address: ', address)

  // Initialize our editionDrop contract
  const editionDrop = useEditionDrop('0xD7B9Ae1B2fAC7C2da444AFE04B5A9B2F7838db0a')
  // Initialize our token contract
  const token = useToken('0x5FD27064FD29777811b70FA6f7fA8C4b1f04Df62')
  // Initialize our governance contract
  const vote = useVote('0xcF9d9816d4c8602B1651A0522d6aC567689dC2Fb')
  // State variable for us to know if user has our NFT
  const [hasClaimedNFT, setHasClaimedNFT] = useState(false)
  // isClaiming lets us keep a loading state while the NFT is minting
  const [isClaiming, setIsClaiming] = useState(false)
  // Holds the amount of token each member has in state
  const [memberTokenAmounts, setMemberTokenAmounts] = useState([])
  // the array holding all of our member's addresses
  const [memberAddresses, setMemberAddresses] = useState([])


  // shorten the wallet address, mkay?
  const shortenAddress = (str) => {
    return str.substring(0,6) + '...' + str.substring(str.length - 4)
  }

  const [proposals, setProposals] = useState([])
  const [isVoting, setIsVoting] = useState(false)
  const [hasVoted, setHasVoted] = useState(false)

  // Retrieve all our existing proposals from the contract
  useEffect(() => {
    if (!hasClaimedNFT) {
      return;
    }
    // Call to grab proposals
    const getAllProposals = async () => {
      try {
        const proposals = await vote.getAll()
        setProposals(proposals)
      } catch (err) {
        console.log('🛑 Failed to get proposals', err)
      }
    }
    getAllProposals()
  }, [hasClaimedNFT, vote])

  // We also need to check if the user has already voted
  useEffect(() => {
    if (!hasClaimedNFT) {
      return
    }

    // Can't check for votes if we don't have proposals
    if (!proposals.length) {
      return
    }

    const checkIfUserHasVoted = async () => {
      try {
        const hasVoted = await vote.hasVoted(proposals[0].proposalId, address)
        setHasVoted(hasVoted)
        if (hasVoted) {
          console.log('🤡 User has already voted')
        } else {
          console.log('😀 User has not voted yet')
        }
      } catch (err) {
        console.error('🛑 Failed to check if wallet has voted', err)
      }
    }
    checkIfUserHasVoted()
  }, [hasClaimedNFT, proposals, address, vote])

  // Grab all the addresses of our members holding our NFT
  useEffect(() => {
    if (!hasClaimedNFT) {
      return;
    }

    const getAllAddresses = async () => {
      try {
        const memberAddresses = await editionDrop.history.getAllClaimerAddresses(0)
        setMemberAddresses(memberAddresses)
        console.log('🚀 Members addresses', memberAddresses)
      } catch (err) {
        console.error('🛑 failed to get member list')
      }
    }
    getAllAddresses()
  }, [hasClaimedNFT, editionDrop.history])

  // get the number of tokens each member holds
  useEffect(() => {
    if (!hasClaimedNFT) {
      return
    }

    const getAllBalances = async () => {
      try {
        const amounts = await token.history.getAllHolderBalances()
        setMemberTokenAmounts(amounts)
        console.log('🔐 Amounts', amounts)
      } catch (err) {
        console.error('🛑 failed to get member balances', err)
      }
    }
    getAllBalances()
  }, [hasClaimedNFT, token.history])

  // Combine member addresses and token amounts
  const memberList = useMemo(() => {
    return memberAddresses.map((address) => {
      const member = memberTokenAmounts?.find(({ holder }) => holder === address)
      return {
        address,
        tokenAmount: member?.balance.displayValue || '0'
      }
    })
  }, [memberAddresses, memberTokenAmounts])

  useEffect(() => {
    // if they don't have a connected wallet, exit
    if (!address) {
      return;
    }

    const checkBalance = async () => {
      try {
        const balance = await editionDrop.balanceOf(address, 0);
        if (balance.gt(0)) {
          setHasClaimedNFT(true);
          console.log('🔥 this user has a membership NFT!')
        } else {
          setHasClaimedNFT(false)
          console.log('😞 this user doesn\'t have a membership NFT')
        }
      } catch (err) {
        setHasClaimedNFT(false);
        console.error('🛑 Failed to get balance', err)
      }
    }
    checkBalance()
  }, [address, editionDrop])

  const mintNFT = async () => {
    try {
      setIsClaiming(true);
      await editionDrop.claim('0', 1)
      console.log(`🌊 Successfully minted! Check it out on OpenSea: https://testnets.opensea.io/assets/${editionDrop.getAddress()}/0`);
      setHasClaimedNFT(true)
    } catch (err) {
      setHasClaimedNFT(false);
      console.error('🛑 Failed to mint NFT', err)
    } finally {
      setIsClaiming(false)
    }
  }

  if (address && (network?.[0].data.chain.id !== ChainId.Rinkeby)) {
    return (
      <div className='unsupported-network'>
        <h2>Please connect to Rinkeby</h2>
        <p>
          This dapp only works on the Rinkeby network, please switch networks in your connected wallet.
        </p>
      </div>
    )
  }

  // this is the case where the user hasn't connected their wallet
  // to your web app. Let them call connectWallet
  if (!address) {
    return (
      <div className='landing'>
        <h1>Welcome to PonziDAO</h1>
        <button onClick={connectWithMetamask} className='btn-hero'>
          Connect your wallet
        </button>
      </div>
    )
  }

  // User has a PonziDAO NFT
  if (hasClaimedNFT) {
    return (
      <div className='member-page'>
        <h1>🪙 PonziDAO Member Page</h1>
        <p>Congratulations on being a sucker, errr.... member!</p>
        <div>
          <div>
            <h2>Member List</h2>
            <table className='card'>
              <thead>
              <tr>
                <th>Address</th>
                <th>Token Amount</th>
              </tr>
              </thead>
              <tbody>
              {memberList.map((member) => {
                return (
                  <tr key={member.address}>
                    <td>{shortenAddress(member.address)}</td>
                    <td>{member.tokenAmount}</td>
                  </tr>
                )
              })}
              </tbody>
            </table>
          </div>
          <div>
            <h2>Active Proposals</h2>
            <form
              onSubmit={async (e) => {
                e.preventDefault()
                e.stopPropagation()

                // before we do async things, disable button to prevent double clicks
                setIsVoting(true)

                // get the votes from the form for the values
                const votes = proposals.map((proposal) => {
                  const voteResult = {
                    proposalId: proposal.proposalId,
                    // abstain by default
                    vote: 2,
                  }
                  proposal.votes.forEach((vote) => {
                    const elem = document.getElementById(
                      proposal.proposalId + '-' + vote.type
                    )

                    if (elem.checked) {
                      voteResult.vote = vote.type
                      return
                    }
                  })
                  return voteResult
                })

                // first we need to make sure th user delegates their token to vote
                try {
                  // we'll check if the wallet still needs to delegate their tokens before they can vote
                  const delegation = await token.getDelegationOf(address)
                  // if the delegation address is 0x0 that means they have not delegated their governance tokens yet
                  if (delegation === AddressZero) {
                    // delegate tokens before voting
                    await token.delegateTo(address)
                  }
                  // then we need to vote on the proposals
                  try {
                    await Promise.all(
                      votes.map(async ({proposalId, vote: _vote}) => {
                        // before voting we first need to check whether the proposal is open for voting
                        const proposal = await vote.get(proposalId)
                        // state === 1 means it is open for voting
                        if (proposal.state === 1) {
                          return vote.vote(proposalId, _vote)
                        }
                        // if not open for voting, just continue on
                        return
                      })
                    )
                    try {
                      // if any proposals are ready to be executed, we need to execute them (state === 4)
                      await Promise.all(
                        votes.map(async ({ proposalId}) => {
                          // get the latest state
                          const proposal = await vote.get(proposalId)
                          if (proposal.state === 4) {
                            return vote.execute(proposalId)
                          }
                        })
                      )
                      // if we get here it means we successfully voted
                      setHasVoted(true)
                      console.log('✅ Successfully voted!')
                    } catch(err) {
                      console.error('🛑Failed to execute votes', err)
                    }
                  } catch (err) {
                    console.error('🛑 Failed to vote', err)
                  }
                } catch (err) {
                  console.error('🛑 Failed to delegate tokens')
                } finally {
                  setIsVoting(false)
                }
              }}
              >
              {proposals.map((proposal) => (
                <div key={proposal.proposalId} className='card'>
                  <h5>{proposal.description}</h5>
                  <div>
                    {proposal.votes.map(({type, label}) => (
                      <div key={type}>
                        <input
                          type='radio'
                          id={proposal.proposalId + '-' + type}
                          name={proposal.proposalId}
                          value={type}
                          defaultChecked={type === 2}
                        />
                        <label htmlFor={proposal.proposalId + '-' + type}>
                          {label}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
              <button disabled={isVoting || hasVoted} type='submit'>
                {isVoting ? 'Voting...' : hasVoted ? 'You already voted' : 'Submit Votes' }
              </button>
              {!hasVoted && (
                <small>This will trigger multiple transactions that you will need to sign.</small>
              )}
            </form>
          </div>
        </div>
      </div>
    )
  }

  // this is the case where we have the user's address
  // which means they've connected their wallet to our site
  return (
    <div className='mint-nft'>
      <h1>Mint your free 🪙PonziDAO Membership NFT</h1>
      <button disabled={isClaiming} onClick={mintNFT}>
        {isClaiming ? 'Minting...' : 'Mint your nft (FREE)!'}
      </button>
    </div>
  )
}

export default App
